.frameOutput{
    width: 500px;
    height: 500px;
  }

.display--flex-nowrap{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.margin--10px{
  margin: 10px 10px;
}